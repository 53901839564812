import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { stripeService } from '../services/stripe/stripeService';
import { AuthContext } from './AuthContext';

const StripeContext = createContext();

export const StripeProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const { authenticated, user } = useContext(AuthContext);

  const clearError = () => setError(null);

  const getSubscriptionStatus = useCallback(async () => {
    if (!authenticated) return null;
    
    try {
      setLoading(true);
      const subscriptionData = await stripeService.getSubscriptionStatus();
      setSubscription(subscriptionData);
      return subscriptionData;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [authenticated]);

  useEffect(() => {
    if (authenticated) {
      getSubscriptionStatus().catch(console.error);
    }
  }, [authenticated]);

  const initiateCheckout = useCallback(async (priceId) => {
    try {
      setLoading(true);
      setError(null);
      
      if (!authenticated) {
        throw new Error('Please log in to subscribe');
      }

      const { sessionId } = await stripeService.createCheckoutSession(priceId);
      
      const { loadStripe } = await import('@stripe/stripe-js');
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
      
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }

      const { error: stripeError } = await stripe.redirectToCheckout({ sessionId });
      if (stripeError) {
        throw stripeError;
      }
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [authenticated]);

  const cancelSubscription = useCallback(async () => {
    try {
      setLoading(true);
      await stripeService.cancelSubscription();
      await getSubscriptionStatus();
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [getSubscriptionStatus]);

  const value = {
    loading,
    error,
    subscription,
    initiateCheckout,
    getSubscriptionStatus,
    cancelSubscription,
    clearError
  };

  return (
    <StripeContext.Provider value={value}>
      {children}
    </StripeContext.Provider>
  );
};

export const useStripe = () => {
  const context = useContext(StripeContext);
  if (!context) {
    throw new Error('useStripe must be used within a StripeProvider');
  }
  return context;
};

export default StripeProvider;
