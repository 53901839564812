import axios from 'axios';

// Create an Axios instance using the base URL from the environment variables
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
});

// Add a response interceptor to handle errors (e.g. redirecting on 401 errors)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      // Only redirect if the URL is not an auth-related endpoint
      const requestUrl = error.config.url;
      if (!requestUrl.startsWith('/auth/')) {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
