// src/contexts/MessageContext.js

import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import axiosInstance from '../utils/axiosInstance';
import { MESSAGE_TYPES } from '../constants/messageTypes';

export const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  const [conversations, setConversations] = useState({});
  const [activeConversationId, setActiveConversationId] = useState(null);
  const [loading, setLoading] = useState(true);
  const messageQueue = useRef(new Map());
  const conversationLoadPromises = useRef(new Map());
  const conversationCache = useRef(new Map());

  // Helper to update a conversation's summary:
  const updateConversationSummary = useCallback((conversationId, newSummary) => {
    setConversations((prev) => {
      if (!prev[conversationId]) return prev;
      return {
        ...prev,
        [conversationId]: {
          ...prev[conversationId],
          summary: newSummary,
        },
      };
    });
  }, []);

  // Message Queue Management
  const queueMessage = useCallback((conversationId, message) => {
    if (!messageQueue.current.has(conversationId)) {
      messageQueue.current.set(conversationId, []);
    }
    messageQueue.current.get(conversationId).push(message);
  }, []);

  const processMessageQueue = useCallback(async (conversationId) => {
    // Wait for any pending conversation load
    if (conversationLoadPromises.current.has(conversationId)) {
      await conversationLoadPromises.current.get(conversationId);
    }

    const messages = messageQueue.current.get(conversationId) || [];
    if (messages.length > 0) {
      setConversations((prev) => {
        const conversation = prev[conversationId] || { messages: [], userMessages: {} };
        const updatedMessages = [...conversation.messages];
        const updatedUserMessages = { ...conversation.userMessages };

        messages.forEach((message) => {
          // Check duplicates using messageId instead of id
          if (!updatedMessages.find((m) => m.messageId === message.messageId)) {
            updatedMessages.push(message);
            if (message.parentId) {
              if (!updatedUserMessages[message.parentId]) {
                updatedUserMessages[message.parentId] = [];
              }
              updatedUserMessages[message.parentId].push(message);
            }
          }
        });

        const updatedConversation = {
          ...conversation,
          messages: updatedMessages,
          userMessages: updatedUserMessages,
          updatedAt: new Date().toISOString(),
        };

        // Update cache after processing
        conversationCache.current.set(conversationId, updatedConversation);

        return {
          ...prev,
          [conversationId]: updatedConversation,
        };
      });
      messageQueue.current.delete(conversationId);
    }
  }, []);

  const fetchConversations = useCallback(async () => {
    try {
      const response = await axiosInstance.get('/conversations');
      const fetchedConversations = response.data.conversations;

      const conversationsMap = {};
      fetchedConversations.forEach((conv) => {
        conversationsMap[conv.conversationId] = {
          messages: [],
          userMessages: {},
          archived: conv.archived,
          summary: conv.summary,
          updatedAt: conv.updatedAt,
          isLocked: false,
        };
      });

      setConversations(conversationsMap);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMessages = useCallback(
    async (conversationId) => {
      if (conversationLoadPromises.current.has(conversationId)) {
        return conversationLoadPromises.current.get(conversationId);
      }

      // Check cache first
      if (conversationCache.current.has(conversationId)) {
        const cachedConversation = conversationCache.current.get(conversationId);
        setConversations((prev) => ({
          ...prev,
          [conversationId]: cachedConversation,
        }));
        processMessageQueue(conversationId);
        return cachedConversation.messages;
      }

      const promise = (async () => {
        try {
          const response = await axiosInstance.get(
            `/conversations/${conversationId}/messages`
          );
          let fetchedMessages = response.data.messages;

          // Map backend fields to our camelCase message object:
          fetchedMessages = fetchedMessages.map((msg) => ({
            // Instead of an "id" field, we now use "messageId"
            messageId: msg.messageId,
            text: msg.text,
            taskStatus: msg.taskStatus, // formerly msg.task_status
            content: {
              messageText: msg.text, // formerly message_text
              taskStatus: msg.taskStatus,
            },
            timestamp: msg.createdAt || msg.timestamp,
            parentId: msg.parentId, // ensure your backend returns parentId in camelCase
            type: msg.type,
          }));

          console.log('Fetched Messages:', fetchedMessages);

          // Validate timestamps
          fetchedMessages.forEach((msg) => {
            if (!msg.timestamp || isNaN(new Date(msg.timestamp).getTime())) {
              console.error('Invalid or missing timestamp for message:', msg);
            }
          });

          const newConversationData = {
            messages: fetchedMessages,
            userMessages: fetchedMessages.reduce((acc, msg) => {
              if (msg.parentId) {
                if (!acc[msg.parentId]) acc[msg.parentId] = [];
                acc[msg.parentId].push(msg);
              }
              return acc;
            }, {}),
            updatedAt: new Date().toISOString(),
            isLocked: false,
          };

          const oldCachedConversation = conversationCache.current.get(conversationId) || {};
          const mergedConversation = {
            ...oldCachedConversation,
            ...newConversationData,
          };
          conversationCache.current.set(conversationId, mergedConversation);

          setConversations((prev) => {
            const oldConversation = prev[conversationId] || {};
            return {
              ...prev,
              [conversationId]: {
                ...oldConversation,
                ...newConversationData,
              },
            };
          });

          processMessageQueue(conversationId);
          return fetchedMessages;
        } catch (error) {
          console.error(
            `Error fetching messages for conversation ${conversationId}:`,
            error
          );
          throw error;
        } finally {
          conversationLoadPromises.current.delete(conversationId);
        }
      })();

      conversationLoadPromises.current.set(conversationId, promise);
      return promise;
    },
    [processMessageQueue]
  );

  const clearMessages = useCallback(() => {
    setConversations({});
    setActiveConversationId(null);
    messageQueue.current.clear();
    conversationLoadPromises.current.clear();
    conversationCache.current.clear();
  }, []);

  const addMessage = useCallback(
    (conversationId, message) => {
      setConversations((prev) => {
        const conversation = prev[conversationId];
  
        if (!conversation || conversationLoadPromises.current.has(conversationId)) {
          queueMessage(conversationId, message);
          return prev;
        }
  
        // IMPORTANT: Check BOTH messageId and id properties to prevent duplicates
        // Don't use either/or logic here since we need to match both formats
        const isDuplicate = conversation.messages.some(
          (m) => 
            (m.messageId && message.messageId && m.messageId === message.messageId) || 
            (m.id && message.id && m.id === message.id)
        );
        
        if (isDuplicate) {
          console.log('Prevented duplicate message:', message.messageId || message.id);
          return prev;
        }
  
        // Log all messages for this conversation
        console.log(`Messages for ${conversationId} (${conversation.messages.length + 1}):`, 
                    [...conversation.messages.map(m => m.messageId || m.id), message.messageId || message.id]);
  
        const updatedConversation = {
          ...conversation,
          messages: [...conversation.messages, message],
          userMessages: message.parentId
            ? {
                ...conversation.userMessages,
                [message.parentId]: [
                  ...(conversation.userMessages[message.parentId] || []),
                  message,
                ],
              }
            : conversation.userMessages,
          updatedAt: new Date().toISOString(),
        };
  
        // Update cache
        conversationCache.current.set(conversationId, updatedConversation);
  
        return {
          ...prev,
          [conversationId]: updatedConversation,
        };
      });
    },
    [queueMessage]
  );

  const cleanupConversation = useCallback((conversationId) => {
    messageQueue.current.delete(conversationId);
    conversationLoadPromises.current.delete(conversationId);
  }, []);

  const removeConversation = useCallback(
    async (conversationId) => {
      try {
        await axiosInstance.delete(`/conversations/${conversationId}`);

        setConversations((prev) => {
          const updated = { ...prev };
          delete updated[conversationId];
          return updated;
        });

        conversationCache.current.delete(conversationId);
        cleanupConversation(conversationId);

        if (activeConversationId === conversationId) {
          const remainingConvs = Object.keys(conversations).filter(
            (id) => id !== conversationId
          );
          setActiveConversationId(remainingConvs.length > 0 ? remainingConvs[0] : null);
        }
      } catch (error) {
        console.error('Failed to delete conversation:', error);
        throw error;
      }
    },
    [activeConversationId, conversations, cleanupConversation]
  );

  // Create a new conversation
  const addConversation = useCallback(async () => {
    try {
      const response = await axiosInstance.post('/conversations');
      if (response.data.status === 'success') {
        const newConversation = response.data.conversation;
        setConversations((prev) => ({
          ...prev,
          [newConversation.conversationId]: {
            messages: [],
            userMessages: {},
            archived: newConversation.archived,
            updatedAt: newConversation.updatedAt,
            isLocked: false,
          },
        }));
        return newConversation;
      } else {
        throw new Error('Failed to create conversation.');
      }
    } catch (error) {
      console.error('Error creating conversation:', error);
      throw error;
    }
  }, []);

  useEffect(() => {
    fetchConversations();
  }, [fetchConversations]);

  useEffect(() => {
    if (activeConversationId) {
      fetchMessages(activeConversationId);
      return () => cleanupConversation(activeConversationId);
    }
  }, [activeConversationId, fetchMessages, cleanupConversation]);

  const contextValue = useMemo(
    () => ({
      conversations,
      addMessage,
      fetchMessages,
      fetchConversations,
      getMessages: (conversationId) =>
        conversations[conversationId] ||
        { messages: [], userMessages: {}, isLocked: false },
      removeConversation,
      addConversation,
      activeConversationId,
      setActiveConversationId,
      loading,
      clearMessages,
      updateConversationSummary,
    }),
    [
      conversations,
      addMessage,
      fetchMessages,
      fetchConversations,
      removeConversation,
      addConversation,
      activeConversationId,
      loading,
      clearMessages,
      updateConversationSummary,
    ]
  );

  return (
    <MessageContext.Provider value={contextValue}>
      {children}
    </MessageContext.Provider>
  );
};

export default MessageProvider;
