// src/services/stripe/stripeService.js
import axiosInstance from '../../utils/axiosInstance';

export const stripeService = {
  createCheckoutSession: async (priceId) => {
    try {
      const response = await axiosInstance.post('/stripe/create-checkout-session', {
        priceId
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Failed to create checkout session');
    }
  },
  
  getSubscriptionStatus: async () => {
    try {
      const response = await axiosInstance.get('/stripe/subscription-status');
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Failed to get subscription status');
    }
  },
  
  cancelSubscription: async () => {
    try {
      const response = await axiosInstance.post('/stripe/cancel-subscription');
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Failed to cancel subscription');
    }
  }
};