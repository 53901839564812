// src/pages/DashboardPage/Dashboard/ConversationHistory/ConversationHistory.js
import React, { useContext, useState, useRef, useEffect } from 'react';
import { MessageContext } from '../../../../contexts/MessageContext';
import styles from './ConversationHistory.module.css';
import { FaEllipsisV, FaArchive, FaTrash, FaSpinner } from 'react-icons/fa';
import ArchiveConversationModal from '../../../../components/common/Modal/ArchiveConversationModal';
import DeleteConversationModal from '../../../../components/common/Modal/DeleteConversationModal';
import NotificationModal from '../../../../components/common/Modal/NotificationModal';

const ConversationHistory = ({ onSelectConversation }) => { 
  const { conversations, activeConversationId, removeConversation, archiveConversation } = useContext(MessageContext);
  const [menuOpenId, setMenuOpenId] = useState(null);
  const menuRef = useRef(null);
  
  // Loading states per conversation
  const [processingIds, setProcessingIds] = useState({
    archiving: null,
    deleting: null
  });
  
  // Modal states
  const [modalState, setModalState] = useState({
    archiveModal: { isOpen: false, conversationId: null, title: '' },
    deleteModal: { isOpen: false, conversationId: null, title: '' }
  });

  // Notification modal
  const [notification, setNotification] = useState({
    isOpen: false,
    type: 'success',
    title: '',
    message: ''
  });

  // Close menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpenId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const showNotification = (type, title, message) => {
    setNotification({
      isOpen: true,
      type,
      title,
      message
    });
  };

  const toggleMenu = (conversationId, e) => {
    e.stopPropagation();
    // Prevent opening menu for conversations being processed
    if (processingIds.deleting === conversationId || processingIds.archiving === conversationId) {
      return;
    }
    setMenuOpenId(prevId => (prevId === conversationId ? null : conversationId));
  };

  const openDeleteModal = (e, conversationId) => {
    e.stopPropagation();
    e.preventDefault();
    
    // Get conversation title
    const conversation = conversations[conversationId];
    const title = conversation && conversation.summary && conversation.summary.trim() !== '' 
      ? conversation.summary 
      : 'New Chat';
    
    setModalState(prev => ({
      ...prev,
      deleteModal: {
        isOpen: true,
        conversationId,
        title
      }
    }));
    
    setMenuOpenId(null);
  };

  const openArchiveModal = (e, conversationId) => {
    e.stopPropagation();
    e.preventDefault();
    
    // Get conversation title
    const conversation = conversations[conversationId];
    const title = conversation && conversation.summary && conversation.summary.trim() !== '' 
      ? conversation.summary 
      : 'New Chat';
    
    setModalState(prev => ({
      ...prev,
      archiveModal: {
        isOpen: true,
        conversationId,
        title
      }
    }));
    
    setMenuOpenId(null);
  };

  const handleDeleteConfirm = async () => {
    const { conversationId } = modalState.deleteModal;
    if (!conversationId) return;
    
    // Set deleting state for this specific conversation
    setProcessingIds(prev => ({
      ...prev,
      deleting: conversationId
    }));
    
    try {
      console.log('Deleting conversation:', conversationId);
      
      // Call the removeConversation function directly without window.confirm
      const result = await removeConversation(conversationId);
      
      console.log('Delete result:', result);
      
      // Show success notification
      showNotification(
        'success',
        'Conversation Deleted',
        'The conversation has been successfully deleted.'
      );
    } catch (error) {
      console.error('Error deleting conversation:', error);
      
      // Show error notification
      showNotification(
        'error',
        'Delete Failed',
        'There was a problem deleting the conversation. Please try again.'
      );
    } finally {
      // Clear the processing state
      setProcessingIds(prev => ({
        ...prev,
        deleting: null
      }));
      
      // Close the modal
      closeDeleteModal();
    }
  };

  const handleArchiveConfirm = async () => {
    const { conversationId } = modalState.archiveModal;
    if (!conversationId) return;
    
    // Set archiving state for this specific conversation
    setProcessingIds(prev => ({
      ...prev,
      archiving: conversationId
    }));
    
    try {
      console.log('Archiving conversation:', conversationId);
      
      // Call the archiveConversation function directly
      const result = await archiveConversation(conversationId);
      
      console.log('Archive result:', result);
      
      // Show success notification
      showNotification(
        'success',
        'Conversation Archived',
        'The conversation has been moved to your archive.'
      );
    } catch (error) {
      console.error('Error archiving conversation:', error);
      
      // Show error notification
      showNotification(
        'error',
        'Archive Failed',
        'There was a problem archiving the conversation. Please try again.'
      );
    } finally {
      // Clear the processing state
      setProcessingIds(prev => ({
        ...prev,
        archiving: null
      }));
      
      // Close the modal
      closeArchiveModal();
    }
  };

  const closeDeleteModal = () => {
    setModalState(prev => ({
      ...prev,
      deleteModal: {
        isOpen: false,
        conversationId: null,
        title: ''
      }
    }));
  };

  const closeArchiveModal = () => {
    setModalState(prev => ({
      ...prev,
      archiveModal: {
        isOpen: false,
        conversationId: null,
        title: ''
      }
    }));
  };

  const renderConversationItem = (conversationId) => {
    const isActive = conversationId === activeConversationId;
    const isDeleting = processingIds.deleting === conversationId;
    const isArchiving = processingIds.archiving === conversationId;
    const isProcessing = isDeleting || isArchiving;
    
    // Retrieve the conversation details from the context
    const conversation = conversations[conversationId];
    
    // Use the summary if available; otherwise, display "New Chat"
    const title =
      conversation && conversation.summary && conversation.summary.trim() !== ''
        ? conversation.summary
        : 'New Chat';

    return (
      <div
        key={conversationId}
        className={`${styles.conversationItem} ${isActive ? styles.active : ''} ${isProcessing ? styles.processing : ''}`}
        onClick={() => !isProcessing && onSelectConversation(conversationId)}
        role="button"
        tabIndex={0}
        onKeyPress={(e) => { if (e.key === 'Enter') onSelectConversation(conversationId); }}
        aria-pressed={isActive}
        aria-busy={isProcessing}
      >
        <div className={styles.conversationInfo}>
          <span className={styles.conversationTitle}>
            {title}
          </span>
          {isProcessing && (
            <span className={styles.processingIndicator}>
              <FaSpinner className={styles.spinnerIcon} /> 
              {isDeleting ? 'Deleting...' : 'Archiving...'}
            </span>
          )}
        </div>
        
        {!isProcessing && (
          <div className={styles.menuContainer} ref={menuOpenId === conversationId ? menuRef : null}>
            <button 
              className={styles.menuButton}
              onClick={(e) => toggleMenu(conversationId, e)}
              aria-haspopup="true"
              aria-expanded={menuOpenId === conversationId}
              aria-label={`Menu for conversation ${conversationId.slice(0, 8)}`}
            >
              <FaEllipsisV />
            </button>
            {menuOpenId === conversationId && (
              <div className={styles.menuDropdown}>
                <button 
                  onClick={(e) => openArchiveModal(e, conversationId)} 
                  className={styles.menuItem}
                >
                  <FaArchive /> Archive
                </button>
                <button 
                  onClick={(e) => openDeleteModal(e, conversationId)}
                  className={styles.menuItem}
                  style={{ color: '#ff4d4d' }}
                >
                  <FaTrash /> Delete
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.conversationHistory}>
      <div className={styles.header}>
        <h2>Recent Conversations</h2>
      </div>
      <div className={styles.conversationsList}>
        {Object.keys(conversations).length > 0 ? (
          Object.keys(conversations).map((conversationId) => (
            renderConversationItem(conversationId)
          ))
        ) : (
          <p className={styles.noConversations}>No conversations available.</p>
        )}
      </div>

      {/* Archive Modal */}
      <ArchiveConversationModal
        isOpen={modalState.archiveModal.isOpen}
        conversationTitle={modalState.archiveModal.title}
        onConfirm={handleArchiveConfirm}
        onCancel={closeArchiveModal}
        isLoading={processingIds.archiving === modalState.archiveModal.conversationId}
      />

      {/* Delete Modal */}
      <DeleteConversationModal
        isOpen={modalState.deleteModal.isOpen}
        conversationTitle={modalState.deleteModal.title}
        onConfirm={handleDeleteConfirm}
        onCancel={closeDeleteModal}
        isLoading={processingIds.deleting === modalState.deleteModal.conversationId}
      />
      
      {/* Notification Modal */}
      <NotificationModal
        isOpen={notification.isOpen}
        type={notification.type}
        title={notification.title}
        message={notification.message}
        onClose={() => setNotification(prev => ({ ...prev, isOpen: false }))}
      />
    </div>
  );
};

export default ConversationHistory;