// src/components/Settings2FA/Settings2FA.js
import React, { useState, useEffect } from 'react';
import { FaShieldAlt, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';
import TwoFactorSetup from '../TwoFactorSetup/TwoFactorSetup';
import styles from './Settings2FA.module.css';

const Settings2FA = ({ user, onUpdate }) => {
  const [showSetup, setShowSetup] = useState(false);
  const [isDisabling, setIsDisabling] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);

  // Update the local state when user prop changes
  useEffect(() => {
    if (user) {
      console.log('2FA Status from user object:', user.twoFactorEnabled);
      setTwoFactorEnabled(user.twoFactorEnabled || false);
    }
  }, [user]);

  // Debug: Log when component renders with current state
  useEffect(() => {
    console.log('Settings2FA rendering with twoFactorEnabled:', twoFactorEnabled);
  }, [twoFactorEnabled]);

  const handleSetupSuccess = () => {
    setShowSetup(false);
    setTwoFactorEnabled(true);
    if (onUpdate) {
      onUpdate({ ...user, twoFactorEnabled: true });
    }
  };

  const handleDisable2FA = async () => {
    if (!verificationCode) {
      setError('Please enter your verification code');
      return;
    }

    try {
      setIsLoading(true);
      setError('');
      
      await axiosInstance.post('/2fa/disable', { token: verificationCode });
      
      setTwoFactorEnabled(false);
      if (onUpdate) {
        onUpdate({ ...user, twoFactorEnabled: false });
      }
      
      setIsDisabling(false);
      setVerificationCode('');
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to disable 2FA. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (showSetup) {
    return (
      <TwoFactorSetup 
        onClose={() => setShowSetup(false)} 
        onSuccess={handleSetupSuccess}
      />
    );
  }

  if (isDisabling) {
    return (
      <div className={styles.container}>
        <h2 className={styles.title}>Disable Two-Factor Authentication</h2>
        <p className={styles.description}>
          To disable two-factor authentication, please enter the verification code from your authenticator app:
        </p>
        
        {error && <div className={styles.error}>{error}</div>}
        
        <div className={styles.inputContainer}>
          <input
            type="text"
            className={styles.verificationInput}
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
            placeholder="000000"
            maxLength={6}
          />
        </div>
        
        <div className={styles.buttons}>
          <button 
            className={`${styles.button} ${styles.buttonSecondary}`}
            onClick={() => {
              setIsDisabling(false);
              setVerificationCode('');
              setError('');
            }}
            disabled={isLoading}
          >
            Cancel
          </button>
          <button 
            className={styles.button}
            onClick={handleDisable2FA}
            disabled={isLoading || verificationCode.length !== 6}
          >
            {isLoading ? 'Disabling...' : 'Disable 2FA'}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <FaShieldAlt className={styles.icon} />
          <div className={styles.headerText}>
            <h2 className={styles.title}>Two-Factor Authentication</h2>
            <p className={styles.subtitle}>
              Add an extra layer of security to your account
            </p>
          </div>
        </div>
        <div className={styles.headerRight}>
          {twoFactorEnabled ? (
            <div className={styles.enabledStatus}>
              <FaToggleOn className={styles.enabledIcon} />
              <span>Enabled</span>
            </div>
          ) : (
            <div className={styles.disabledStatus}>
              <FaToggleOff className={styles.disabledIcon} />
              <span>Disabled</span>
            </div>
          )}
        </div>
      </div>
      
      <p className={styles.description}>
        Two-factor authentication requires you to enter a verification code from your authenticator app
        in addition to your password when signing in.
      </p>
      
      {twoFactorEnabled ? (
        <button 
          className={`${styles.button} ${styles.disableButton}`}
          onClick={() => setIsDisabling(true)}
        >
          Disable 2FA
        </button>
      ) : (
        <button 
          className={styles.button}
          onClick={() => setShowSetup(true)}
        >
          Enable 2FA
        </button>
      )}
    </div>
  );
};

export default Settings2FA;