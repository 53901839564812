// src/components/MessageGroup/MessageGroup.js

import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeSanitize from 'rehype-sanitize';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ThumbsUp, ThumbsDown, Copy, Check } from 'lucide-react';
import TaskStatusDropdown from '../../pages/DashboardPage/Dashboard/TaskStatusDropdown/TaskStatusDropdown';
import styles from './MessageGroup.module.css';

// CopyButton component that shows a checkmark for 3 seconds on success
const CopyButton = ({ text, className, size = 16 }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error("Copy failed:", error);
    }
  };

  return (
    <button className={className} onClick={handleCopy} aria-label="Copy text">
      {copied ? <Check size={size} /> : <Copy size={size} />}
    </button>
  );
};

CopyButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  size: PropTypes.number,
};

// FeedbackButtons component that shows mutually exclusive thumbs buttons
const FeedbackButtons = ({ messageId, text }) => {
  const [feedback, setFeedback] = useState(null);

  const handleUp = () => {
    setFeedback('up');
    console.log(`Feedback up for message: ${messageId}`);
    // Optionally, call your API here to persist the thumbs-up
  };

  const handleDown = () => {
    setFeedback('down');
    console.log(`Feedback down for message: ${messageId}`);
    // Optionally, call your API here to persist the thumbs-down
  };

  return (
    <div className={styles.iconRow}>
      {feedback !== 'down' && (
        <button
          className={styles.iconButton}
          onClick={handleUp}
          aria-label="Thumbs up"
        >
          <ThumbsUp size={16} />
        </button>
      )}
      {feedback !== 'up' && (
        <button
          className={styles.iconButton}
          onClick={handleDown}
          aria-label="Thumbs down"
        >
          <ThumbsDown size={16} />
        </button>
      )}
      <CopyButton text={text} className={styles.iconButton} size={16} />
    </div>
  );
};

FeedbackButtons.propTypes = {
  messageId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const MessageGroup = ({ messages }) => {
  
  const groupedMessages = useMemo(() => {
    const groups = {};
  
    // Log the full messages array for debugging
    console.log('All messages received by MessageGroup:', messages);
  
    // First, filter out any duplicate messages by id/messageId
    const uniqueMessages = [];
    const seenIds = new Set();
    
    messages.forEach(msg => {
      const msgId = msg.messageId || msg.id;
      if (!seenIds.has(msgId)) {
        seenIds.add(msgId);
        uniqueMessages.push(msg);
      }
    });
  
    // Now group the unique messages
    uniqueMessages.forEach((msg) => {
      const msgId = msg.messageId || msg.id;
      const parentId = msg.parentId || msg.parent_id;
  
      if (msg.type === 'MESSAGE_TYPE_USER_MESSAGE') {
        // Create a new group for user messages
        groups[msgId] = {
          user_message: msg,
          responses: [],
          final_result: null,
        };
      } else if ((msg.type === 'MESSAGE_TYPE_RESPONSE_USER_MESSAGE' || msg.type === 'MESSAGE_TYPE_INTERIM_RESULT') && parentId) {
        // Add response to existing group or create a new one
        if (!groups[parentId]) {
          groups[parentId] = {
            responses: [],
            final_result: null,
          };
        }
        groups[parentId].responses.push(msg);
      } else if (msg.type === 'MESSAGE_TYPE_FINAL_RESULT' && parentId) {
        // Add final result to existing group or create a new one
        if (!groups[parentId]) {
          groups[parentId] = {
            responses: [],
            final_result: null,
          };
        }
        groups[parentId].final_result = msg;
      }
    });
  
    // Sort responses by timestamp within each group
    Object.values(groups).forEach((group) => {
      if (group.responses && group.responses.length > 0) {
        group.responses.sort((a, b) => {
          // Ensure both timestamps are valid
          const timeA = new Date(a.timestamp).getTime();
          const timeB = new Date(b.timestamp).getTime();
          
          // If either timestamp is invalid, sort by the sequence number if available
          if (isNaN(timeA) || isNaN(timeB)) {
            return (a.sequence || 0) - (b.sequence || 0);
          }
          
          return timeA - timeB;
        });
        
        // Log the number of responses in each group
        console.log(`Group with responses: parentId=${Object.keys(group)[0]}, responses=${group.responses.length}`);
      }
    });
  
    // Return array of groups that have either a user message or responses
    return Object.values(groups).filter(
      (group) => group.user_message || group.responses.length > 0
    );
  }, [messages]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      console.error('Invalid timestamp:', timestamp);
      return 'Invalid Date';
    }
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  return (
    <div className="flex-1 overflow-y-auto px-4">
      <div className="max-w-3xl mx-auto">
        {groupedMessages.map((group, index) => (
          <div key={`message-group-${index}`} className={styles.messageWrapper}>
            {/* User Message */}
            {group.user_message && (
              <div className={classNames(styles.message, styles.messageUser)}>
                <div className={styles.messageText}>
                  <div>{group.user_message.text}</div>
                  <div className={styles.messageTimestamp}>
                    {formatTimestamp(group.user_message.timestamp)}
                  </div>
                </div>
              </div>
            )}

            {/* Task Status Dropdown */}
            {(group.responses.length > 0 || group.final_result) && (
              <TaskStatusDropdown
                responses={group.responses}
                finalResult={group.final_result}
              />
            )}

            {/* Assistant Response */}
            {group.final_result && (
              <div
                className={classNames(styles.message, styles.messageAssistant)}
                role="region"
                aria-label="Assistant response"
              >
                <div className={styles.messageText}>
                  <div className={styles.markdownContent}>
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeSanitize]}
                      components={{
                        p: ({ node, ...props }) => (
                          <p className={styles.paragraph} {...props} />
                        ),
                        code: ({ node, inline, ...props }) => (
                          <code
                            className={inline ? styles.inlineCode : styles.codeBlock}
                            {...props}
                          />
                        ),
                      }}
                    >
                      {group.final_result.text}
                    </ReactMarkdown>
                  </div>
                  <div className={styles.messageTimestamp}>
                    {formatTimestamp(group.final_result.timestamp)}
                  </div>

                  {/* Feedback and Copy Buttons */}
                  <FeedbackButtons
                    messageId={group.final_result.messageId || group.final_result.id}
                    text={group.final_result.text}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

MessageGroup.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      messageId: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.string.isRequired,
      text: PropTypes.string,
      content: PropTypes.shape({
        messageText: PropTypes.string,
      }),
      timestamp: PropTypes.string.isRequired,
      parentId: PropTypes.string,
      parent_id: PropTypes.string,
    })
  ).isRequired,
};

MessageGroup.defaultProps = {
  messages: [],
};

export default MessageGroup;