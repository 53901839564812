// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import axiosInstance from '../utils/axiosInstance';
import { useLocation } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const location = useLocation(); // Get current location

  const checkAuth = useCallback(async () => {
    console.log('Checking auth...');
    setLoading(true);
    try {
      const response = await axiosInstance.get('/auth/user');
      console.log('Auth response:', response.data);
      if (response.data.authenticated) {
        setAuthenticated(true);
        setUser(response.data.user);
      } else {
        setAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        setAuthenticated(false);
        setUser(null);
      } else {
        console.error('Auth check error:', error);
        setAuthenticated(false);
        setUser(null);
        setError('Failed to authenticate.');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const refreshAuth = useCallback(async () => {
    console.log('Refreshing authentication state...');
    try {
      const response = await axiosInstance.get('/auth/user');
      console.log('Auth refresh response:', response.data);
      
      if (response.data.authenticated) {
        setAuthenticated(true);
        setUser(response.data.user);
        console.log('Updated user state after refresh:', response.data.user);
      } else {
        setAuthenticated(false);
        setUser(null);
      }
      return response.data.user;
    } catch (error) {
      console.error('Error refreshing authentication:', error);
      setAuthenticated(false);
      setUser(null);
      return null;
    }
  }, []);

  const handleLogout = useCallback(async () => {
    try {
      await axiosInstance.post('/auth/logout');
      setAuthenticated(false);
      setUser(null);
    } catch (error) {
      console.error('Error during logout:', error);
      setAuthenticated(false);
      setUser(null);
    }
  }, []);

  const addConversation = useCallback(async () => {
    try {
      const response = await axiosInstance.post('/conversations');
      if (response.data.status === 'success') {
        const newConversation = response.data.conversation;
        return newConversation;
      } else {
        throw new Error('Failed to create conversation.');
      }
    } catch (error) {
      console.error('Error creating conversation:', error);
      throw error;
    }
  }, []);

  // Function to enable 2FA
  const enable2FA = useCallback(async (verificationCode) => {
    try {
      const response = await axiosInstance.post('/auth/enable-2fa', { 
        verificationCode 
      });
      
      if (response.data.status === 'success') {
        // Update user data to reflect 2FA is enabled
        await refreshAuth();
        return { success: true };
      } else {
        return { 
          success: false, 
          error: response.data.message || 'Failed to enable 2FA' 
        };
      }
    } catch (error) {
      console.error('Error enabling 2FA:', error);
      return { 
        success: false, 
        error: error.response?.data?.message || 'An error occurred while enabling 2FA'
      };
    }
  }, [refreshAuth]);

  // Function to disable 2FA
  const disable2FA = useCallback(async (verificationCode) => {
    try {
      const response = await axiosInstance.post('/auth/disable-2fa', { 
        verificationCode 
      });
      
      if (response.data.status === 'success') {
        // Update user data to reflect 2FA is disabled
        await refreshAuth();
        return { success: true };
      } else {
        return { 
          success: false, 
          error: response.data.message || 'Failed to disable 2FA' 
        };
      }
    } catch (error) {
      console.error('Error disabling 2FA:', error);
      return { 
        success: false, 
        error: error.response?.data?.message || 'An error occurred while disabling 2FA'
      };
    }
  }, [refreshAuth]);

  // Function to verify 2FA during login
  const verify2FA = useCallback(async (email, verificationCode) => {
    try {
      const response = await axiosInstance.post('/auth/verify-2fa', {
        email,
        verificationCode
      });
      
      if (response.data.success) {
        setAuthenticated(true);
        setUser(response.data.user);
        return { success: true, user: response.data.user };
      } else {
        return { 
          success: false, 
          error: response.data.message || 'Invalid verification code'
        };
      }
    } catch (error) {
      console.error('Error verifying 2FA:', error);
      return { 
        success: false, 
        error: error.response?.data?.message || 'An error occurred during verification'
      };
    }
  }, []);

  useEffect(() => {
    checkAuth();
    const intervalId = setInterval(() => {
      if (authenticated) {
        refreshAuth();
      }
    }, 15 * 60 * 1000); // Refresh every 15 minutes

    return () => clearInterval(intervalId);
  }, [checkAuth, refreshAuth, authenticated]);

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        user,
        setUser,
        refreshAuth,
        loading,
        error,
        handleLogout,
        addConversation,
        checkAuth,
        enable2FA,
        disable2FA,
        verify2FA
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};