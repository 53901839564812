// src/components/common/Modal/Enable2FAModal.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { FaShieldAlt, FaCheck, FaCopy } from 'react-icons/fa';
import styles from './Enable2FAModal.module.css';

const Enable2FAModal = ({
  isOpen,
  onClose,
  onConfirm,
  qrCodeUrl,
  secretKey,
  isLoading = false
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [step, setStep] = useState(1);
  const [hasCopied, setHasCopied] = useState(false);

  const handleCopySecretKey = () => {
    navigator.clipboard.writeText(secretKey);
    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 2000);
  };

  const handleVerify = () => {
    // Call the onConfirm function with the verification code
    onConfirm(verificationCode);
  };

  const renderStepOne = () => (
    <div className={styles.content}>
      <div className={styles.iconContainer}>
        <FaShieldAlt className={styles.icon} />
      </div>
      <h3 className={styles.subtitle}>Secure Your Account</h3>
      <p className={styles.description}>
        Two-factor authentication adds an extra layer of security to your account. 
        Once enabled, you'll need to enter a verification code from your authentication app each time you sign in.
      </p>
      <div className={styles.steps}>
        <div className={styles.step}>
          <div className={styles.stepNumber}>1</div>
          <p>Download and install an authenticator app like Google Authenticator or Authy</p>
        </div>
        <div className={styles.step}>
          <div className={styles.stepNumber}>2</div>
          <p>Scan the QR code or enter the secret key manually</p>
        </div>
        <div className={styles.step}>
          <div className={styles.stepNumber}>3</div>
          <p>Enter the verification code from the app to complete setup</p>
        </div>
      </div>
      <button onClick={() => setStep(2)} className={styles.actionButton}>
        Continue
      </button>
    </div>
  );

  const renderStepTwo = () => (
    <div className={styles.content}>
      <h3 className={styles.subtitle}>Scan QR Code</h3>
      <p className={styles.description}>
        Scan this QR code with your authenticator app:
      </p>
      
      <div className={styles.qrCodeContainer}>
        {qrCodeUrl ? (
          <img src={qrCodeUrl} alt="QR Code for 2FA setup" className={styles.qrCode} />
        ) : (
          <div className={styles.qrCodePlaceholder}>QR Code will appear here</div>
        )}
      </div>
      
      <div className={styles.secretKeyContainer}>
        <p className={styles.secretKeyLabel}>Or enter this secret key manually:</p>
        <div className={styles.secretKeyWrapper}>
          <code className={styles.secretKey}>{secretKey || 'XXXX XXXX XXXX XXXX'}</code>
          <button 
            onClick={handleCopySecretKey} 
            className={styles.copyButton}
            aria-label="Copy secret key"
          >
            {hasCopied ? <FaCheck /> : <FaCopy />}
          </button>
        </div>
      </div>
      
      <button onClick={() => setStep(3)} className={styles.actionButton}>
        Next
      </button>
    </div>
  );

  const renderStepThree = () => (
    <div className={styles.content}>
      <h3 className={styles.subtitle}>Verify Setup</h3>
      <p className={styles.description}>
        Enter the 6-digit verification code from your authenticator app:
      </p>
      
      <div className={styles.verificationContainer}>
        <input
          type="text"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, '').substring(0, 6))}
          className={styles.verificationInput}
          placeholder="000000"
          maxLength={6}
          autoComplete="one-time-code"
        />
      </div>
      
      <button 
        onClick={handleVerify} 
        className={styles.actionButton}
        disabled={verificationCode.length !== 6 || isLoading}
      >
        {isLoading ? 'Verifying...' : 'Enable 2FA'}
      </button>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      title="Enable Two-Factor Authentication"
      onClose={onClose}
      size="medium"
      className={styles.modal}
    >
      <div className={styles.container}>
        <div className={styles.progressBar}>
          <div className={`${styles.progressStep} ${step >= 1 ? styles.active : ''}`}>
            Setup
          </div>
          <div className={`${styles.progressStep} ${step >= 2 ? styles.active : ''}`}>
            Scan
          </div>
          <div className={`${styles.progressStep} ${step >= 3 ? styles.active : ''}`}>
            Verify
          </div>
        </div>
        
        {step === 1 && renderStepOne()}
        {step === 2 && renderStepTwo()}
        {step === 3 && renderStepThree()}
      </div>
    </Modal>
  );
};

Enable2FAModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  qrCodeUrl: PropTypes.string,
  secretKey: PropTypes.string,
  isLoading: PropTypes.bool
};

export default Enable2FAModal;