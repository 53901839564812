// src/components/common/Modal/UpgradeModal.js

import React from 'react';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';

const UpgradeModal = ({ isOpen, onUpgradeClick }) => {
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      title="Subscription Required"
      // Do not pass an onClose prop so that no close button appears.
      size="small"
    >
      <div className="p-4">
        <p className="mb-4">
          You need a paid plan to access our product features. Please purchase a subscription.
        </p>
        <div className="flex justify-end gap-4">
          <Button variant="primary" onClick={onUpgradeClick}>
            I Want To Purchase!
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpgradeModal;
