// src/components/common/Modal/DeleteConversationModal.js
import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationModal from './ConfirmationModal';
import { FaTrash } from 'react-icons/fa';
import styles from './DeleteConversationModal.module.css';

const DeleteConversationModal = ({
  isOpen,
  conversationTitle,
  onConfirm,
  onCancel,
  isLoading = false
}) => {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      title="Delete Conversation"
      message={
        <div className={styles.content}>
          <div className={styles.iconContainer}>
            <FaTrash className={styles.icon} />
          </div>
          <p>
            Are you sure you want to delete "<span className={styles.title}>{conversationTitle}</span>"?
            <br/>
            <small className={styles.note}>This action cannot be undone.</small>
          </p>
        </div>
      }
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmText={isLoading ? "Deleting..." : "Delete"}
      type="danger"
      isLoading={isLoading}
    />
  );
};

DeleteConversationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  conversationTitle: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default DeleteConversationModal;