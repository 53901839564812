// src/components/common/Modal/ExportDataModal.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { FaDownload, FaFile, FaFileAlt, FaFileCsv, FaFileCode } from 'react-icons/fa';
import styles from './ExportDataModal.module.css';

const ExportDataModal = ({
  isOpen,
  onClose,
  onExport,
  isLoading = false
}) => {
  const [selectedFormat, setSelectedFormat] = useState('json');
  const [dateRange, setDateRange] = useState('all');
  const [customStartDate, setCustomStartDate] = useState('');
  const [customEndDate, setCustomEndDate] = useState('');

  const handleExport = () => {
    const exportConfig = {
      format: selectedFormat,
      dateRange,
      startDate: customStartDate || undefined,
      endDate: customEndDate || undefined
    };
    
    onExport(exportConfig);
  };

  const formatOptions = [
    { id: 'json', name: 'JSON', icon: FaFileCode, description: 'Machine-readable format, best for developers or data import' },
    { id: 'csv', name: 'CSV', icon: FaFileCsv, description: 'Spreadsheet-compatible format, good for analysis in Excel' },
    { id: 'txt', name: 'Text', icon: FaFileAlt, description: 'Simple text format, easy to read and search' },
    { id: 'html', name: 'HTML', icon: FaFile, description: 'Web page format, preserves formatting for viewing in browsers' }
  ];

  const dateRangeOptions = [
    { id: 'all', name: 'All time' },
    { id: 'month', name: 'Last 30 days' },
    { id: 'week', name: 'Last 7 days' },
    { id: 'custom', name: 'Custom range' }
  ];

  return (
    <Modal
      isOpen={isOpen}
      title="Export Your Data"
      onClose={onClose}
      size="medium"
    >
      <div className={styles.content}>
        <div className={styles.iconContainer}>
          <FaDownload className={styles.icon} />
        </div>
        
        <p className={styles.description}>
          Download your conversation history and account data.
          Choose your preferred format and date range below.
        </p>
        
        <div className={styles.sectionTitle}>Format</div>
        <div className={styles.formatOptions}>
          {formatOptions.map(format => (
            <div 
              key={format.id}
              className={`${styles.formatOption} ${selectedFormat === format.id ? styles.selected : ''}`}
              onClick={() => setSelectedFormat(format.id)}
            >
              <div className={styles.formatIconContainer}>
                <format.icon className={styles.formatIcon} />
              </div>
              <div className={styles.formatDetails}>
                <div className={styles.formatName}>{format.name}</div>
                <div className={styles.formatDescription}>{format.description}</div>
              </div>
            </div>
          ))}
        </div>
        
        <div className={styles.sectionTitle}>Date Range</div>
        <div className={styles.dateRangeOptions}>
          {dateRangeOptions.map(range => (
            <div 
              key={range.id}
              className={`${styles.dateRangeOption} ${dateRange === range.id ? styles.selected : ''}`}
              onClick={() => setDateRange(range.id)}
            >
              {range.name}
            </div>
          ))}
        </div>
        
        {dateRange === 'custom' && (
          <div className={styles.customDateRange}>
            <div className={styles.dateInput}>
              <label htmlFor="startDate">Start Date</label>
              <input 
                id="startDate"
                type="date" 
                value={customStartDate}
                onChange={(e) => setCustomStartDate(e.target.value)}
              />
            </div>
            <div className={styles.dateInput}>
              <label htmlFor="endDate">End Date</label>
              <input 
                id="endDate"
                type="date" 
                value={customEndDate}
                onChange={(e) => setCustomEndDate(e.target.value)}
              />
            </div>
          </div>
        )}
        
        <div className={styles.actionButtons}>
          <button 
            className={styles.cancelButton}
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className={styles.exportButton}
            onClick={handleExport}
            disabled={isLoading || (dateRange === 'custom' && (!customStartDate || !customEndDate))}
          >
            {isLoading ? 'Exporting...' : 'Export Data'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

ExportDataModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default ExportDataModal;