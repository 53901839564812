// src/pages/DashboardPage/Dashboard/TaskStatusDropdown/TaskStatusDropdown.js

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ChevronUp, ChevronDown, Loader2, CheckCircle2 } from 'lucide-react';
import styles from './TaskStatusDropdown.module.css';

const TaskStatusDropdown = ({ responses, finalResult }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Enhanced logging for debugging
  console.log('TaskStatusDropdown - received responses:', responses);
  console.log('TaskStatusDropdown - response count:', responses ? responses.length : 0);
  
  // Log each response ID to check for uniqueness
  if (responses && responses.length > 0) {
    console.log('TaskStatusDropdown - response IDs:', 
      responses.map(r => ({
        id: r.id,
        messageId: r.messageId,
        text: r.text?.substring(0, 30) || 'N/A'
      }))
    );
  }
  
  console.log('TaskStatusDropdown - finalResult:', finalResult);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Ensure we have valid responses
  if (!Array.isArray(responses) || responses.length === 0) return null;

  // Filter responses to only include those with meaningful content
  const validResponses = responses.filter(r => {
    const hasText = r && (r.content?.messageText || r.text);
    return hasText;
  });

  console.log('TaskStatusDropdown - validResponses count:', validResponses.length);

  // Don't render if no valid responses
  if (validResponses.length === 0) return null;
  
  // Determine if the task is complete using finalResult
  const isComplete = finalResult?.taskStatus === 'TASK_STATUS_COMPLETED';

  // Calculate processing time based on timestamps
  const userMessage = validResponses.find(msg => msg.type === 'MESSAGE_TYPE_USER_MESSAGE') || validResponses[0];
  const finalMessage = finalResult || validResponses[validResponses.length - 1];
  
  const startTime = new Date(userMessage.timestamp);
  const endTime = new Date(finalMessage.timestamp);
  const processingTime = !isNaN(startTime) && !isNaN(endTime) 
    ? Math.max(0, Math.round((endTime - startTime) / 1000)) // Prevent negative times
    : 0;

  console.log('TaskStatusDropdown - isComplete:', isComplete);
  console.log('TaskStatusDropdown - processingTime:', processingTime);

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div className={styles.dropdownWrapper}>
        <button 
          className={styles.dropdownSummary}
          onClick={(e) => {
            e.stopPropagation(); // Prevent event bubbling
            setIsOpen(!isOpen);
          }}
          aria-haspopup="true"
          aria-expanded={isOpen}
        >
          {isComplete ? (
            <>
              <CheckCircle2 className={styles.checkIcon} />
              <span>Worked on tasks for {processingTime} seconds</span>
            </>
          ) : (
            <>
              <Loader2 className={`${styles.clockIcon} ${styles.spin}`} />
              <span>Working on tasks...</span>
            </>
          )}
          {isOpen ? (
            <ChevronDown className={styles.dropdownIcon} />
          ) : (
            <ChevronUp className={styles.dropdownIcon} />
          )}
        </button>
      </div>

      {isOpen && (
        <div className={styles.processingSteps}>
          <div className={styles.timelineContainer}>
            {validResponses.map((response, index) => {
              // Get message text from either content.messageText or text property
              const messageText = response?.content?.messageText || response?.text || '';
              const timestamp = response?.timestamp ? new Date(response.timestamp) : new Date();
              const formattedTime = !isNaN(timestamp) 
                ? timestamp.toLocaleTimeString([], { 
                    hour: '2-digit', 
                    minute: '2-digit' 
                  })
                : '';

              // Only render if there's actual message text
              if (!messageText) return null;

              // Generate a reliable unique key
              const uniqueKey = response.messageId || response.id || `resp-${index}-${Date.now()}`;

              return (
                <div 
                  key={uniqueKey} 
                  className={styles.processingStep}
                >
                  <div className={styles.stepContent}>
                    <div className={styles.stepText}>{messageText}</div>
                    <div className={styles.stepTimestamp}>{formattedTime}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

TaskStatusDropdown.propTypes = {
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      messageId: PropTypes.string,
      id: PropTypes.string,
      type: PropTypes.string,
      content: PropTypes.shape({
        messageText: PropTypes.string,
      }),
      text: PropTypes.string,
      timestamp: PropTypes.string,
      parentId: PropTypes.string,
    })
  ).isRequired,
  finalResult: PropTypes.shape({
    messageId: PropTypes.string,
    type: PropTypes.string,
    content: PropTypes.shape({
      messageText: PropTypes.string,
      taskStatus: PropTypes.string,
    }),
    text: PropTypes.string,
    timestamp: PropTypes.string,
    parentId: PropTypes.string,
  }),
};

TaskStatusDropdown.defaultProps = {
  finalResult: null,
};

export default TaskStatusDropdown;