import React, { useState, useContext, useEffect } from 'react';
import {
  FaCog,
  FaShieldAlt,
  FaDatabase,
  FaBell,
  FaPalette,
  FaGlobe,
  FaKey,
  FaSignOutAlt,
  FaArchive,
  FaTrash,
  FaLink,
  FaDownload
} from 'react-icons/fa';
import styles from './SettingsPage.module.css';
import ConfirmationModal from '../../../../components/common/Modal/ConfirmationModal';
import NotificationModal from '../../../../components/common/Modal/NotificationModal';
import Enable2FAModal from '../../../../components/common/Modal/Enable2FAModal';
import ExportDataModal from '../../../../components/common/Modal/ExportDataModal';
import DeleteAccountModal from '../../../../components/common/Modal/DeleteAccountModal';
import Settings2FA from '../../../../components/Settings2FA/Settings2FA';
import { MessageContext } from '../../../../contexts/MessageContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import axiosInstance from '../../../../utils/axiosInstance';

const SettingsPage = () => {
  const { user, refreshAuth, setUser } = useContext(AuthContext);
  const { fetchConversations } = useContext(MessageContext);
  const [activeTab, setActiveTab] = useState('general');
  const [notifications, setNotifications] = useState(true);
  const [theme, setTheme] = useState('dark');
  const [language, setLanguage] = useState('english');

  // Loading States
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSignOut, setLoadingSignOut] = useState(false);
  const [loading2FA, setLoading2FA] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingDeleteAccount, setLoadingDeleteAccount] = useState(false);

  // 2FA States
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [secretKey, setSecretKey] = useState('');

  // Modal States
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [signOutModalOpen, setSignOutModalOpen] = useState(false);
  const [enable2FAModalOpen, setEnable2FAModalOpen] = useState(false);
  const [exportDataModalOpen, setExportDataModalOpen] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [notificationModal, setNotificationModal] = useState({
    isOpen: false,
    type: 'success',
    title: '',
    message: ''
  });

  // Debug: Log user object when it changes
  useEffect(() => {
    if (user) {
      console.log('User object in SettingsPage:', user);
      console.log('2FA Status:', user.twoFactorEnabled);
    }
  }, [user]);

  // Effect to fetch 2FA data when modal opens
  useEffect(() => {
    if (enable2FAModalOpen) {
      fetchTwoFactorSetupData();
    }
  }, [enable2FAModalOpen]);

  const fetchTwoFactorSetupData = async () => {
    try {
      setLoading2FA(true);
      // Updated endpoint to POST /2fa/setup (matches back-end)
      const response = await axiosInstance.post('/2fa/setup');
      console.log('2FA setup response:', response.data);
      
      // Expecting backend to return { success: true, qrCode: <url>, secret: <base32 secret> }
      if (response.data && response.data.qrCode && response.data.secret) {
        setQrCodeUrl(response.data.qrCode);
        setSecretKey(response.data.secret);
      }
    } catch (error) {
      console.error('Error fetching 2FA setup data:', error);
      showNotification('error', 'Error', 'Failed to initialize two-factor authentication.');
    } finally {
      setLoading2FA(false);
    }
  };

  const showNotification = (type, title, message) => {
    setNotificationModal({
      isOpen: true,
      type,
      title,
      message
    });
  };

  const handleSignOutAllDevices = async () => {
    setLoadingSignOut(true);
    try {
      const response = await axiosInstance.post('/auth/signout-all');
      if (response.data.status === 'success') {
        showNotification('success', 'Success', 'Successfully signed out of all devices');
      } else {
        showNotification('error', 'Error', 'Failed to sign out of all devices');
      }
    } catch (error) {
      console.error('Error signing out:', error);
      showNotification('error', 'Error', 'An error occurred while signing out of devices');
    } finally {
      setLoadingSignOut(false);
      setSignOutModalOpen(false);
    }
  };

  const handleDeleteAllConversations = async () => {
    setLoadingDelete(true);
    try {
      const response = await axiosInstance.delete('/conversations/deleteAll');
      if (response.data.status === 'success') {
        await fetchConversations(); // Fetch fresh conversation list
        showNotification('success', 'Success', 'All chats have been deleted successfully');
      } else {
        showNotification('error', 'Error', 'Failed to delete all chats');
      }
    } catch (error) {
      console.error('Error deleting all chats:', error);
      showNotification('error', 'Error', 'An error occurred while deleting chats');
    } finally {
      setLoadingDelete(false);
      setDeleteModalOpen(false);
    }
  };

  const handleArchiveAll = async () => {
    setLoadingArchive(true);
    try {
      const response = await axiosInstance.post('/conversations/archiveAll');
      if (response.data.status === 'success') {
        await fetchConversations(); // Fetch fresh conversation list
        showNotification('success', 'Success', 'All chats have been archived successfully');
      } else {
        showNotification('error', 'Error', 'Failed to archive all chats');
      }
    } catch (error) {
      console.error('Error archiving all chats:', error);
      showNotification('error', 'Error', 'An error occurred while archiving chats');
    } finally {
      setLoadingArchive(false);
      setArchiveModalOpen(false);
    }
  };

  const handle2FASetup = async (verificationCode) => {
    setLoading2FA(true);
    try {
      // Updated endpoint to POST /2fa/verify and send the token as "token"
      const response = await axiosInstance.post('/2fa/verify', {
        token: verificationCode
      });
      
      console.log('2FA verification response:', response.data);
      
      if (response.data.success || response.data.status === 'success') {
        showNotification('success', 'Success', 'Two-factor authentication has been successfully enabled');
        setEnable2FAModalOpen(false);
        
        // Update user info to reflect 2FA is now enabled
        try {
          // Get fresh user data
          const authResponse = await axiosInstance.get('/auth/user');
          if (authResponse.data.authenticated && authResponse.data.user) {
            // Update the user state with the fresh data
            setUser(authResponse.data.user);
            console.log('Updated user after enabling 2FA:', authResponse.data.user);
          }
        } catch (refreshError) {
          console.error('Error refreshing user data after 2FA setup:', refreshError);
        }
      } else {
        showNotification('error', 'Error', 'Invalid verification code. Please try again.');
      }
    } catch (error) {
      console.error('Error enabling 2FA:', error);
      showNotification('error', 'Error', 'Failed to enable two-factor authentication');
    } finally {
      setLoading2FA(false);
    }
  };

  const handleExportData = async (exportConfig) => {
    setLoadingExport(true);
    try {
      const response = await axiosInstance.post('/data/export', exportConfig, {
        responseType: 'blob'
      });
      
      // Create a download link for the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      // Set the filename based on the export format
      const timestamp = new Date().toISOString().split('T')[0];
      link.setAttribute('download', `data-export-${timestamp}.${exportConfig.format}`);
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
      showNotification('success', 'Success', 'Your data has been exported successfully');
      setExportDataModalOpen(false);
    } catch (error) {
      console.error('Error exporting data:', error);
      showNotification('error', 'Error', 'Failed to export your data');
    } finally {
      setLoadingExport(false);
    }
  };

  const handleDeleteAccount = async () => {
    setLoadingDeleteAccount(true);
    try {
      const response = await axiosInstance.delete('/auth/account');
      
      if (response.data.status === 'success') {
        window.location.href = '/logout'; // Redirect to logout page
      } else {
        showNotification('error', 'Error', 'Failed to delete account');
      }
    } catch (error) {
      console.error('Error deleting account:', error);
      showNotification('error', 'Error', 'An error occurred while deleting your account');
    } finally {
      setLoadingDeleteAccount(false);
      setDeleteAccountModalOpen(false);
    }
  };

  // Handle user updates (e.g., when 2FA status changes)
  const handleUserUpdate = async (updatedUser) => {
    try {
      console.log('Handling user update with:', updatedUser);
      
      // Get fresh user data from the server
      const response = await axiosInstance.get('/auth/user');
      console.log('Auth response after update:', response.data);
      
      if (response.data.authenticated) {
        // Update the global user context with fresh data
        setUser(response.data.user);
        console.log('Updated user context with fresh data:', response.data.user);
        
        showNotification('success', 'Success', '2FA settings updated successfully');
      } else {
        console.warn('Authentication failed after user update');
        showNotification('error', 'Error', 'Failed to update user settings');
      }
    } catch (error) {
      console.error('Error updating user data:', error);
      showNotification('error', 'Error', 'Failed to update 2FA settings');
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'general':
        return (
          <div className={styles.settingSection}>
            <div className={styles.sectionHeader}>
              <FaCog className={styles.sectionIcon} />
              <h2>General Settings</h2>
            </div>
            <div className={styles.settingGroup}>
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaBell className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Notifications</h3>
                    <p>Receive updates about your conversations</p>
                  </div>
                </div>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={notifications}
                    onChange={() => setNotifications(!notifications)}
                  />
                  <span className={styles.slider}></span>
                </label>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaPalette className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Theme</h3>
                    <p>Choose your preferred appearance</p>
                  </div>
                </div>
                <select
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  className={styles.select}
                >
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                  <option value="system">System</option>
                </select>
              </div>

              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaGlobe className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Language</h3>
                    <p>Select your preferred language</p>
                  </div>
                </div>
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className={styles.select}
                >
                  <option value="english">English</option>
                  <option value="spanish">Espa�ol</option>
                  <option value="french">Fran�ais</option>
                </select>
              </div>
            </div>
          </div>
        );

      case 'security':
        return (
          <div className={styles.settingSection}>
            <div className={styles.sectionHeader}>
              <FaShieldAlt className={styles.sectionIcon} />
              <h2>Security Settings</h2>
            </div>
            <div className={styles.settingGroup}>
              {/* Integrated Settings2FA component */}
              <Settings2FA user={user} onUpdate={handleUserUpdate} />

              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaSignOutAlt className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Active Sessions</h3>
                    <p>Manage your logged-in devices</p>
                  </div>
                </div>
                <button 
                  className={styles.actionButton}
                  onClick={() => setSignOutModalOpen(true)}
                  disabled={loadingSignOut}
                >
                  {loadingSignOut ? 'Signing Out...' : 'Sign Out All Devices'}
                </button>
              </div>
            </div>
          </div>
        );

      case 'data':
        return (
          <div className={styles.settingSection}>
            <div className={styles.sectionHeader}>
              <FaDatabase className={styles.sectionIcon} />
              <h2>Data Controls</h2>
            </div>
            <div className={styles.settingGroup}>
              {/* Improve Model Toggle */}
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaDatabase className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Improve the model for everyone</h3>
                    <p>Help improve the model by sharing your conversations</p>
                  </div>
                </div>
                <label className={styles.switch}>
                  <input
                    type="checkbox"
                    checked={true}
                    onChange={() => {}} // Add your toggle handler here
                  />
                  <span className={styles.slider}></span>
                </label>
              </div>

              {/* Shared Links */}
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaLink className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Shared links</h3>
                    <p>Manage your shared conversation links</p>
                  </div>
                </div>
                <button className={`${styles.actionButton} ${styles.manageButton}`}>
                  Manage
                </button>
              </div>

              {/* Export Data */}
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaDownload className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Export data</h3>
                    <p>Download your conversation history</p>
                  </div>
                </div>
                <button 
                  className={`${styles.actionButton} ${styles.exportButton}`}
                  onClick={() => setExportDataModalOpen(true)}
                  disabled={loadingExport}
                >
                  {loadingExport ? 'Exporting...' : 'Export'}
                </button>
              </div>

              {/* Delete Account */}
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaTrash className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Delete account</h3>
                    <p>Permanently delete your account and all data</p>
                  </div>
                </div>
                <button 
                  className={`${styles.actionButton} ${styles.deleteButton}`}
                  onClick={() => setDeleteAccountModalOpen(true)}
                  disabled={loadingDeleteAccount}
                >
                  {loadingDeleteAccount ? 'Deleting...' : 'Delete'}
                </button>
              </div>

              {/* Archive Conversations */}
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaArchive className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Archive Conversations</h3>
                    <p>Move all conversations to archive</p>
                  </div>
                </div>
                <button
                  className={`${styles.actionButton} ${styles.archiveButton}`}
                  onClick={() => setArchiveModalOpen(true)}
                  disabled={loadingArchive}
                >
                  {loadingArchive ? 'Archiving...' : 'Archive All'}
                </button>
              </div>

              {/* Delete All Data */}
              <div className={styles.settingItem}>
                <div className={styles.settingHeader}>
                  <FaTrash className={styles.settingIcon} />
                  <div className={styles.settingInfo}>
                    <h3>Delete All Data</h3>
                    <p>Permanently remove all conversations</p>
                  </div>
                </div>
                <button
                  className={`${styles.actionButton} ${styles.deleteButton}`}
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={loadingDelete}
                >
                  {loadingDelete ? 'Deleting...' : 'Delete All'}
                </button>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.settingsPage}>
      <aside className={styles.sidebar}>
        <h2 className={styles.sidebarTitle}>Settings</h2>
        <ul className={styles.sidebarNav}>
          <li
            className={`${styles.sidebarItem} ${activeTab === 'general' ? styles.active : ''}`}
            onClick={() => setActiveTab('general')}
          >
            <FaCog /> General
          </li>
          <li
            className={`${styles.sidebarItem} ${activeTab === 'security' ? styles.active : ''}`}
            onClick={() => setActiveTab('security')}
          >
            <FaShieldAlt /> Security
          </li>
          <li
            className={`${styles.sidebarItem} ${activeTab === 'data' ? styles.active : ''}`}
            onClick={() => setActiveTab('data')}
          >
            <FaDatabase /> Data Controls
          </li>
        </ul>
      </aside>
      <main className={styles.content}>
        {renderContent()}

        {/* Delete Confirmation Modal */}
        <ConfirmationModal
          isOpen={deleteModalOpen}
          title="Delete All Chats"
          message="Are you sure you want to delete all your chats? This action cannot be undone."
          onConfirm={handleDeleteAllConversations}
          onCancel={() => setDeleteModalOpen(false)}
          confirmText={loadingDelete ? 'Deleting...' : 'Delete All'}
          type="danger"
          isLoading={loadingDelete}
        />

        {/* Archive Confirmation Modal */}
        <ConfirmationModal
          isOpen={archiveModalOpen}
          title="Archive All Chats"
          message="Are you sure you want to archive all your chats?"
          onConfirm={handleArchiveAll}
          onCancel={() => setArchiveModalOpen(false)}
          confirmText={loadingArchive ? 'Archiving...' : 'Archive All'}
          type="warning"
          isLoading={loadingArchive}
        />

        {/* Sign Out Confirmation Modal */}
        <ConfirmationModal
          isOpen={signOutModalOpen}
          title="Sign Out All Devices"
          message="Are you sure you want to sign out of all devices? You will need to log in again on each device."
          onConfirm={handleSignOutAllDevices}
          onCancel={() => setSignOutModalOpen(false)}
          confirmText={loadingSignOut ? 'Signing Out...' : 'Sign Out All'}
          type="warning"
          isLoading={loadingSignOut}
        />

        {/* Enable 2FA Modal */}
        <Enable2FAModal
          isOpen={enable2FAModalOpen}
          onClose={() => setEnable2FAModalOpen(false)}
          onConfirm={handle2FASetup}
          qrCodeUrl={qrCodeUrl}
          secretKey={secretKey}
          isLoading={loading2FA}
        />

        {/* Export Data Modal */}
        <ExportDataModal
          isOpen={exportDataModalOpen}
          onClose={() => setExportDataModalOpen(false)}
          onExport={handleExportData}
          isLoading={loadingExport}
        />

        {/* Delete Account Modal */}
        <DeleteAccountModal
          isOpen={deleteAccountModalOpen}
          onClose={() => setDeleteAccountModalOpen(false)}
          onConfirm={handleDeleteAccount}
          isLoading={loadingDeleteAccount}
        />

        {/* Notification Modal */}
        <NotificationModal
          isOpen={notificationModal.isOpen}
          type={notificationModal.type}
          title={notificationModal.title}
          message={notificationModal.message}
          onClose={() => setNotificationModal(prev => ({ ...prev, isOpen: false }))}
        />
      </main>
    </div>
  );
};

export default SettingsPage;