// src/components/common/Modal/DeleteAccountModal.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { FaExclamationTriangle, FaTrash } from 'react-icons/fa';
import styles from './DeleteAccountModal.module.css';

const DeleteAccountModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading = false
}) => {
  const [confirmation, setConfirmation] = useState('');
  const [isFirstStep, setIsFirstStep] = useState(true);

  const handleProceed = () => {
    setIsFirstStep(false);
  };

  const handleConfirm = () => {
    onConfirm();
  };

  const isConfirmationValid = confirmation.toLowerCase() === 'delete my account';

  return (
    <Modal
      isOpen={isOpen}
      title="Delete Account"
      onClose={onClose}
      size="small"
    >
      {isFirstStep ? (
        <div className={styles.content}>
          <div className={styles.warningIconContainer}>
            <FaExclamationTriangle className={styles.warningIcon} />
          </div>
          
          <h3 className={styles.warningTitle}>Warning: This action cannot be undone</h3>
          
          <div className={styles.warningContent}>
            <p>Deleting your account will:</p>
            <ul className={styles.warningList}>
              <li>Permanently delete all your conversations and data</li>
              <li>Cancel any active subscriptions</li>
              <li>Remove all your personal information from our systems</li>
              <li>Prevent you from recovering your account in the future</li>
            </ul>
            
            <p className={styles.alternativeAction}>
              If you're experiencing issues with our service, please consider 
              <button className={styles.contactLink} onClick={() => window.location.href = '/support'}>
                contacting support
              </button> 
              before deleting your account.
            </p>
          </div>
          
          <div className={styles.actionButtons}>
            <button 
              className={styles.cancelButton}
              onClick={onClose}
            >
              Cancel
            </button>
            <button 
              className={styles.proceedButton}
              onClick={handleProceed}
            >
              Proceed
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.deleteIconContainer}>
            <FaTrash className={styles.deleteIcon} />
          </div>
          
          <p className={styles.confirmText}>
            To confirm deletion, please type <strong>delete my account</strong> below:
          </p>
          
          <input
            type="text"
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
            className={styles.confirmationInput}
            placeholder="delete my account"
          />
          
          <div className={styles.actionButtons}>
            <button 
              className={styles.backButton}
              onClick={() => setIsFirstStep(true)}
              disabled={isLoading}
            >
              Back
            </button>
            <button 
              className={styles.deleteButton}
              onClick={handleConfirm}
              disabled={!isConfirmationValid || isLoading}
            >
              {isLoading ? 'Deleting...' : 'Delete Account Permanently'}
            </button>
          </div>
        </div>
      )}
    </Modal>
  );
};

DeleteAccountModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
};

export default DeleteAccountModal;