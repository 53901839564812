import React, { useEffect, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import styles from './Header.module.css';
import SynchrotronAILogo from '../../assets/images/SynchrotronAI.svg';

function Header() {
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const navigate = useNavigate();
  const { user, handleLogout, loading } = useContext(AuthContext);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setIsHeaderHidden(true);
      } else {
        setIsHeaderHidden(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  const handleDashboardClick = () => {
    navigate('/dashboard');
  };

  return (
    <header className={`${styles.header} ${isHeaderHidden ? styles.headerHidden : ''}`}>
      <div className={styles.logo}>
        <Link to="/" className={styles.logoLink}>
          <img
            src={SynchrotronAILogo}
            alt="Synchrotron AI Logo"
            className={styles.logoImage}
          />
          <span className={styles.companyName}>SynchrotronAI</span>
        </Link>
      </div>
      
      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li><Link to="/research" className={styles.navLink}>Research</Link></li>
          <li><Link to="/products" className={styles.navLink}>Products</Link></li>
          <li><Link to="/careers" className={styles.navLink}>Careers</Link></li>
          <li><Link to="/about-us" className={styles.navLink}>About Us</Link></li>
        </ul>
      </nav>

      <div className={styles.authButtons}>
        {loading ? (
          <div className={styles.loadingContainer}>
            <button className={styles.loginSignupButton} disabled>
              Loading...
            </button>
          </div>
        ) : user ? (
          <>
            <button 
              className={`${styles.loginSignupButton} ${styles.dashboardButton}`} 
              onClick={handleDashboardClick}
            >
              Ask Me Anything
            </button>
            <button 
              className={`${styles.loginSignupButton} ${styles.logoutButton}`} 
              onClick={handleLogout}
            >
              Log Out
            </button>
          </>
        ) : (
          <button 
            className={styles.loginSignupButton} 
            onClick={() => navigate('/login')}
          >
            Login/Signup
          </button>
        )}
      </div>
    </header>
  );
}

export default Header;
