// src/components/TwoFactorSetup/TwoFactorSetup.js
import React, { useState, useEffect } from 'react';
import { FaShieldAlt, FaCheck, FaCopy, FaTimes } from 'react-icons/fa';
import axiosInstance from '../../utils/axiosInstance';
import styles from './TwoFactorSetup.module.css';

const TwoFactorSetup = ({ onClose, onSuccess }) => {
  const [step, setStep] = useState(1);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // Fetch the QR code and secret when the component mounts
    const fetchQRCode = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.post('/2fa/setup');
        setQrCodeUrl(response.data.qrCode);
        setSecretKey(response.data.secret);
      } catch (err) {
        setError('Failed to setup two-factor authentication. Please try again.');
        console.error('2FA setup error:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchQRCode();
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(secretKey);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleVerify = async () => {
    if (verificationCode.length !== 6) {
      setError('Please enter a 6-digit verification code');
      return;
    }

    try {
      setIsLoading(true);
      setError('');
      
      await axiosInstance.post('/2fa/verify', { token: verificationCode });
      
      // 2FA is now enabled
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      setError(err.response?.data?.error || 'Invalid verification code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep1 = () => (
    <div className={styles.step}>
      <div className={styles.iconContainer}>
        <FaShieldAlt className={styles.icon} />
      </div>
      <h2 className={styles.title}>Enhance Your Security</h2>
      <p className={styles.description}>
        Two-factor authentication adds an extra layer of security to your account.
        After setup, you'll need to enter a verification code from your authenticator app
        each time you sign in.
      </p>
      <div className={styles.instructions}>
        <h3 className={styles.instructionsTitle}>How to set up:</h3>
        <ol className={styles.instructionsList}>
          <li>Download an authenticator app like Google Authenticator or Authy</li>
          <li>Scan the QR code or enter the secret key manually</li>
          <li>Enter the 6-digit verification code from the app</li>
        </ol>
      </div>
      <button 
        className={styles.button}
        onClick={() => setStep(2)}
        disabled={isLoading || !qrCodeUrl}
      >
        Continue
      </button>
    </div>
  );

  const renderStep2 = () => (
    <div className={styles.step}>
      <h2 className={styles.title}>Scan QR Code</h2>
      <p className={styles.description}>
        Open your authenticator app and scan this QR code:
      </p>
      
      <div className={styles.qrCodeContainer}>
        {qrCodeUrl ? (
          <img src={qrCodeUrl} alt="QR code for 2FA setup" className={styles.qrCode} />
        ) : (
          <div className={styles.qrCodePlaceholder}>Loading QR code...</div>
        )}
      </div>
      
      <div className={styles.secretKeyContainer}>
        <p className={styles.secretKeyLabel}>Or enter this secret key manually:</p>
        <div className={styles.secretKeyWrapper}>
          <code className={styles.secretKey}>{secretKey}</code>
          <button 
            className={styles.copyButton}
            onClick={handleCopy}
            disabled={!secretKey}
            aria-label="Copy secret key"
          >
            {copied ? <FaCheck /> : <FaCopy />}
          </button>
        </div>
      </div>
      
      <div className={styles.buttons}>
        <button 
          className={`${styles.button} ${styles.buttonSecondary}`}
          onClick={() => setStep(1)}
        >
          Back
        </button>
        <button 
          className={styles.button}
          onClick={() => setStep(3)}
          disabled={isLoading || !secretKey}
        >
          Next
        </button>
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className={styles.step}>
      <h2 className={styles.title}>Verify Setup</h2>
      <p className={styles.description}>
        Enter the 6-digit verification code from your authenticator app:
      </p>
      
      {error && <div className={styles.error}>{error}</div>}
      
      <div className={styles.verificationContainer}>
        <input
          type="text"
          className={styles.verificationInput}
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
          placeholder="000000"
          maxLength={6}
        />
      </div>
      
      <div className={styles.buttons}>
        <button 
          className={`${styles.button} ${styles.buttonSecondary}`}
          onClick={() => setStep(2)}
          disabled={isLoading}
        >
          Back
        </button>
        <button 
          className={styles.button}
          onClick={handleVerify}
          disabled={isLoading || verificationCode.length !== 6}
        >
          {isLoading ? 'Verifying...' : 'Enable 2FA'}
        </button>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <button className={styles.closeButton} onClick={onClose} aria-label="Close">
        <FaTimes />
      </button>
      
      <div className={styles.progress}>
        <div className={`${styles.progressStep} ${step >= 1 ? styles.active : ''}`}>
          <div className={styles.progressNumber}>1</div>
          <span className={styles.progressLabel}>Info</span>
        </div>
        <div className={styles.progressLine}></div>
        <div className={`${styles.progressStep} ${step >= 2 ? styles.active : ''}`}>
          <div className={styles.progressNumber}>2</div>
          <span className={styles.progressLabel}>Scan</span>
        </div>
        <div className={styles.progressLine}></div>
        <div className={`${styles.progressStep} ${step >= 3 ? styles.active : ''}`}>
          <div className={styles.progressNumber}>3</div>
          <span className={styles.progressLabel}>Verify</span>
        </div>
      </div>
      
      {step === 1 && renderStep1()}
      {step === 2 && renderStep2()}
      {step === 3 && renderStep3()}
    </div>
  );
};

export default TwoFactorSetup;