// src/contexts/WebSocketContext.js

import React, { createContext, useContext, useRef, useState, useEffect } from 'react';
import { MessageContext } from './MessageContext';

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { addMessage, activeConversationId, updateConversationSummary } = useContext(MessageContext);
  const [isConnected, setIsConnected] = useState(false);
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const MAX_RECONNECT_DELAY = 5000;
  const MAX_RETRIES = 3;
  const reconnectAttemptsRef = useRef(0);
  const messageQueueRef = useRef([]);
  const messageCounterRef = useRef(0); // Add a counter for sequential message IDs

  const cleanup = () => {
    if (wsRef.current) {
      console.log('Cleaning up WebSocket connection');
      wsRef.current.onclose = null;
      wsRef.current.onerror = null;
      wsRef.current.onmessage = null;
      
      if (wsRef.current.readyState === WebSocket.OPEN) {
        wsRef.current.close();
      }
      
      wsRef.current = null;
    }
    setIsConnected(false);
    
    if (reconnectTimeoutRef.current) {
      clearTimeout(reconnectTimeoutRef.current);
      reconnectTimeoutRef.current = null;
    }
  };

  const handleWebSocketMessage = (event) => {
    try {
      const raw = JSON.parse(event.data);
      console.log('Raw WebSocket message:', raw);
      
      if (!raw.type) return;

      // Determine if the message is final or a partial update.
      const isFinal = raw.type === 'MESSAGE_TYPE_FINAL_RESULT';

      // Generate a unique and sequential message ID
      const uniqueId = `${wsRef.current.conversationId}-msg-${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
      const messageCounter = messageCounterRef.current++;
      
      const baseMessage = {
        id: uniqueId,
        messageId: raw.messageId || uniqueId, // Preserve original messageId if it exists
        conversationId: wsRef.current.conversationId,
        parentId: raw.parentId,
        sender: raw.type.startsWith('MESSAGE_TYPE_USER') ? 'user' : 'assistant',
        text: raw.content?.messageText || '',
        timestamp: raw.timestamp || new Date().toISOString(),
        type: raw.type,
        taskStatus: raw.content?.taskStatus || null,
        content: raw.content || {},
        // Add a sequence number for easier debugging
        sequence: messageCounter
      };

      console.log('Processed message:', baseMessage);
      addMessage(wsRef.current.conversationId, baseMessage);

      // If it's the final result and contains a sidebar summary, update the conversation summary.
      if (isFinal && baseMessage.content?.sidebarSummary) {
        updateConversationSummary(wsRef.current.conversationId, baseMessage.content.sidebarSummary);
      }
    } catch (error) {
      console.error('Error handling message:', error);
    }
  };

  const connect = () => {
    if (!activeConversationId) {
      console.warn('No active conversation ID, cannot establish WebSocket connection.');
      return;
    }

    cleanup();

    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsUrl = `${protocol}//${window.location.host}/api/ws?conversationId=${activeConversationId}`;
    console.log(`Establishing new WebSocket connection for conversation: ${activeConversationId}`);
    const ws = new WebSocket(wsUrl);
    wsRef.current = ws;
    wsRef.current.conversationId = activeConversationId;

    ws.onopen = () => {
      console.log('WebSocket connected');
      setIsConnected(true);
      reconnectAttemptsRef.current = 0;

      if (messageQueueRef.current.length > 0) {
        console.log(`Sending ${messageQueueRef.current.length} queued messages.`);
        messageQueueRef.current.forEach((msgContent) => {
          const message = {
            schemaVersion: "v1",
            timestamp: new Date().toISOString(),
            type: "MESSAGE_TYPE_USER_MESSAGE",
            // Generate a unique messageId for queued messages
            messageId: `${wsRef.current.conversationId}-msg-${Date.now()}-${Math.random().toString(36).substring(2, 15)}`,
            content: {
              messageText: msgContent.messageText,
              taskStatus: "TASK_STATUS_PENDING",
            },
            parentId: msgContent.parentId,
          };
          ws.send(JSON.stringify(message));
        });
        messageQueueRef.current = [];
      }
    };

    ws.onmessage = handleWebSocketMessage;

    ws.onclose = (event) => {
      console.log('WebSocket closed:', event.code, event.reason);
      setIsConnected(false);
      wsRef.current = null;

      if (reconnectAttemptsRef.current < MAX_RETRIES) {
        const delay = Math.min(1000 * Math.pow(2, reconnectAttemptsRef.current), MAX_RECONNECT_DELAY);
        console.log(`Attempting to reconnect in ${delay}ms...`);
        reconnectTimeoutRef.current = setTimeout(() => {
          reconnectAttemptsRef.current += 1;
          connect();
        }, delay);
      } else {
        console.error('Max WebSocket reconnection attempts reached.');
      }
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      ws.close();
    };
  };

  useEffect(() => {
    if (activeConversationId) {
      // Reset message counter when changing conversations
      messageCounterRef.current = 0;
      connect();
    }

    return () => {
      cleanup();
    };
  }, [activeConversationId]);

  const sendMessage = (messageContent) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const message = {
        schemaVersion: "v1",
        timestamp: new Date().toISOString(),
        type: "MESSAGE_TYPE_USER_MESSAGE",
        // Generate a unique messageId for outgoing messages
        messageId: `${wsRef.current.conversationId}-msg-${Date.now()}-${Math.random().toString(36).substring(2, 15)}`,
        content: {
          messageText: messageContent.messageText,
          taskStatus: "TASK_STATUS_PENDING",
        },
        parentId: messageContent.parentId,
      };
      wsRef.current.send(JSON.stringify(message));
      return true;
    } else {
      console.warn('WebSocket is not connected. Queuing message.');
      messageQueueRef.current.push(messageContent);
      return false;
    }
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{
      isConnected,
      sendMessage,
    }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

export default WebSocketProvider;