// src/pages/DashboardPage/Dashboard/DashboardLayout/DashboardLayout.js

import React, {
  useContext,
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import debounce from 'lodash.debounce';
import styles from './DashboardLayout.module.css';
import { MessageContext } from '../../../../contexts/MessageContext';
import { WebSocketContext } from '../../../../contexts/WebSocketContext';
import { useStripe } from '../../../../contexts/StripeContext';
import MessageGroup from '../../../../components/MessageGroup/MessageGroup';
import Sidebar from '../Sidebar/Sidebar';
import ChatBox from '../ChatBox/ChatBox';
import UpgradeModal from '../../../../components/common/Modal/UpgradeModal';

const DashboardLayout = () => {
  const {
    conversations,
    getMessages,
    fetchMessages,
    activeConversationId,
    setActiveConversationId,
    addConversation,
    clearMessages,
    fetchConversations,
    loading, // from MessageContext, if you have a loading state
  } = useContext(MessageContext);

  const { isConnected } = useContext(WebSocketContext);
  const { subscription } = useStripe();
  const location = useLocation();
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);

  // Basic error/loading states for local tasks
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const loadingTimeoutRef = useRef(null);
  const retryAttemptsRef = useRef(0);
  const MAX_RETRY_ATTEMPTS = 3;

  // Decide whether the chatbox is "centered welcome" vs pinned to bottom
  const [isInitialView, setIsInitialView] = useState(false);

  // Subscription upgrade modal
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    if (subscription && subscription.tier === 'free') {
      setShowUpgradeModal(true);
    } else {
      setShowUpgradeModal(false);
    }
  }, [subscription]);

  const handleUpgradeClick = () => {
    navigate('/pricing');
  };

  /**
   *  Decide if we are in "initial view" by simply checking:
   *  - Is there an active conversation? If not => "centered welcome"
   *  - If there is an active conversation => pinned chat
   */
  useEffect(() => {
    if (loading) return; // Wait until the context finishes loading
    if (!activeConversationId) {
      // Means user hasn't selected anything
      setIsInitialView(true);
    } else {
      setIsInitialView(false);
    }
  }, [loading, activeConversationId]);

  const handleFirstMessageSent = () => {
    // Once user sends a message, that means they definitely have a conversation
    setIsInitialView(false);
  };

  const handleConversationSelect = useCallback(
    async (conversationId) => {
      if (conversationId === activeConversationId) return;

      // We do have an active conversation => no "initial view"
      setIsInitialView(false);

      try {
        clearTimeout(loadingTimeoutRef.current);
        setError(null);
        setIsLoading(true);

        loadingTimeoutRef.current = setTimeout(() => {
          setError('Loading is taking longer than expected. Please try again.');
          setIsLoading(false);
        }, 10000);

        setActiveConversationId(conversationId);
        await fetchMessages(conversationId);

        retryAttemptsRef.current = 0;
      } catch (err) {
        console.error('Error switching conversations:', err);
        setError('Failed to load conversation. Please try again.');
        if (retryAttemptsRef.current < MAX_RETRY_ATTEMPTS) {
          retryAttemptsRef.current++;
          const delay = 1000 * Math.pow(2, retryAttemptsRef.current);
          console.log(`Retrying to switch conversation in ${delay}ms...`);
          await new Promise((resolve) => setTimeout(resolve, delay));
          return handleConversationSelect(conversationId);
        }
      } finally {
        clearTimeout(loadingTimeoutRef.current);
        setIsLoading(false);
      }
    },
    [activeConversationId, setActiveConversationId, fetchMessages]
  );

  const debouncedHandleConversationSelect = useMemo(
    () =>
      debounce((conversationId) => {
        handleConversationSelect(conversationId).catch(console.error);
      }, 300),
    [handleConversationSelect]
  );

  const handleCreateConversation = async () => {
    try {
      setError(null);
      const newConversation = await addConversation();
      if (newConversation && newConversation.conversationId) {
        await handleConversationSelect(newConversation.conversationId);
      }
    } catch (err) {
      console.error('Error creating new conversation:', err);
      setError('Failed to create new conversation. Please try again.');
    }
  };

  const resetChat = useCallback(() => {
    clearMessages();
    setError(null);
    setIsLoading(false);
    retryAttemptsRef.current = 0;
    clearTimeout(loadingTimeoutRef.current);

    // Force the "initial" state
    setIsInitialView(true);

    // Re-fetch conversation list so user sees the updated empty or full list
    fetchConversations();
  }, [clearMessages, fetchConversations]);

  useEffect(() => {
    if (messagesEndRef.current && !isLoading && !isInitialView) {
      const scrollToBottom = () => {
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      };
      const timeoutId = setTimeout(scrollToBottom, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [activeConversationId, conversations, isLoading, isInitialView]);

  useEffect(() => {
    return () => {
      debouncedHandleConversationSelect.cancel();
      clearTimeout(loadingTimeoutRef.current);
    };
  }, [debouncedHandleConversationSelect]);

  const renderMessagesContainer = () => {
    // If it's initial view => do not show old conversation messages
    if (isInitialView) return null;

    if (isLoading) {
      return (
        <div className={styles.loadingContainer}>
          <div className={styles.loadingSpinner}>Loading conversation...</div>
        </div>
      );
    }

    if (error) {
      return (
        <div className={styles.errorContainer}>
          <div className={styles.errorMessage}>{error}</div>
          <button
            className={styles.retryButton}
            onClick={() => {
              if (activeConversationId) {
                retryAttemptsRef.current = 0;
                handleConversationSelect(activeConversationId);
              }
            }}
          >
            Retry
          </button>
        </div>
      );
    }

    // If no conversation is active (and not initial?), might be a quick edge case
    if (!activeConversationId) return null;

    const conversation = getMessages(activeConversationId);
    return <MessageGroup messages={conversation.messages} />;
  };

  // If context is still loading the conversation list, show a spinner
  if (loading) {
    return <div className={styles.loading}>Loading conversations...</div>;
  }

  return (
    <div
      className={`${styles.dashboardLayout} ${
        isInitialView ? styles.initialView : ''
      }`}
    >
      <Sidebar
        activeConversationId={activeConversationId}
        onSelectConversation={debouncedHandleConversationSelect}
        onCreateConversation={handleCreateConversation}
        resetChat={resetChat}
        isInitialView={isInitialView}
      />

      <div className={styles.dashboardContentWrapper}>
        {location.pathname === '/dashboard' ||
        location.pathname === '/dashboard/' ? (
          <div className={styles.mainContent}>
            <div className={styles.messagesContainer}>
              {renderMessagesContainer()}
              <div ref={messagesEndRef} />
            </div>
            <ChatBox
              isInitialState={isInitialView}
              onFirstMessageSent={handleFirstMessageSent}
            />
          </div>
        ) : (
          <Outlet />
        )}
      </div>

      {/* Show upgrade modal if user is on the free tier */}
      <UpgradeModal isOpen={showUpgradeModal} onUpgradeClick={handleUpgradeClick} />
    </div>
  );
};

export default DashboardLayout;